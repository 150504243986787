import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../services/user.service'
Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'proximamente',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/interlogic',
    name: 'Nosotros interlogic',
    component: () => import('../views/nosotrosInterlogic.vue'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/empresa-socialmente-responsable',
    name: 'Interlogic Empresa Socialmente Responsable',
    component: () => import('../views/nosotrosInterlogicESR.vue'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/automotriz',
    name: 'Sector Automotriz',
    component: () => import('../views/SectorAutomotriz'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/gasolinero',
    name: 'Sector Gasolinero',
    component: () => import('../views/SectorGasolinero'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/gasolineras',
    name: 'Sector Gasolinero',
    component: () => import('../views/SectorGasolinero'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/gobierno',
    name: 'Sector Gobierno',
    component: () => import('../views/SectorGobierno'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/servicios',
    name: 'Sector Servicios',
    component: () => import('../views/SectorServicios'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/financiero',
    name: 'Sector Financiero',
    component: () => import('../views/SectorFinanciero'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/cajas-de-deposito',
    name: 'Cajas de Depósito',
    component: () => import('../views/SolucionCajas'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/cajas-de-deposito-cashcontrol',
    name: 'Cajas de Depósito CashControl',
    component: () => import('../views/SolucionCajasCashControl'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/cajas-de-deposito-cashcontrol-reciclador',
    name: 'Cajas de Depósito CashControl Reciclador',
    component: () => import('../views/SolucionCajasCashControlReciclador'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/kioscos-de-atencion',
    name: 'Solucion kioscos de atencion',
    component: () => import('../views/KioscosLanding'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/kioscos-de-atencion-smartkiosk-1',
    name: 'Solucion Cajero de pago 1',
    component: () => import('../views/SolucionKiosco1'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/kioscos-de-atencion-smartkiosk-3ng',
    name: 'Solucion Cajero de pago 3ng',
    component: () => import('../views/SolucionKiosco3ng'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/kioscos-de-atencion-smartkiosk-3ng-dual',
    name: 'Solucion Cajero de pago 3ng dual',
    component: () => import('../views/SolucionKiosco3ngDual'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/mantenimiento',
    name: 'Solucion mantenimiento',
    component: () => import('../views/SolucionMantenimiento'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/refacciones',
    name: 'Solucion refacciones',
    component: () => import('../views/SolucionRefacciones'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/servicio-administrado',
    name: 'Solucion Servicio administrado',
    component: () => import('../views/SolucionServicioAdministrado'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/software-as-a-service',
    name: 'Solucion Software As A Service',
    component: () => import('../views/SolucionSoftware'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/cajeros-de-pago',
    name: 'Solucion Cajero de pago',
    component: () => import('../views/CajerosLanding'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/cajeros-de-pago-smartpay-3ng',
    name: 'Solucion Cajero de pago smartpay 3ng',
    component: () => import('../views/SolucionCajero3NG'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/cajeros-de-pago-smartpay-3ng-reciclador',
    name: 'Solucion Cajero de pago smartpay 3ng reciclador',
    component: () => import('../views/SolucionCajero3NGReciclador'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/cajeros-de-pago-smartpay-4ng',
    name: 'Solucion Cajero de pago smartpay 4ng',
    component: () => import('../views/SolucionCajero4NG'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/cajeros-de-pago-smartpay-gas',
    name: 'Solucion Cajero de pago smartpay gas',
    component: () => import('../views/SolucionCajeroGas'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/cajeros-de-pago-smartpay-autosmart',
    name: 'Solucion Cajero de pago smartpay autosmart',
    component: () => import('../views/SolucionCajeroAutoSmart'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/aviso-de-privacidad',
    name: 'Aviso de Privacidad',
    component: () => import('../views/AvisodePrivacidad'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },


  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/Autentificacion'),
    meta: {
      log: true,
      admin: true,

      auth: false
    }
  },

  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      log: true,
      admin: true,

      auth: true,
    }
  },



  {
    path: '*',
    name: '404',
    component: () => import('../views/Error404'),
    meta: {
      log: false,
      admin: false,

      auth: false
    }
  },
  {
    path: '/blog/expogas-monterrey-2024',
    name: 'EXPOGAS MONTERREY 2024',
    component: () => import('../views/BlogExpoGasolinera'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/blog/expogas-aguascalientes-2025',
    name: 'EXPOGAS AGUASCALIENTES 2025',
    component: () => import('../views/BlogExpoGasolinera2025'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/blog/',
    name: 'Blogs',
    component: () => import('../views/Blogs'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/CajerosLanding',
    name: 'Fabricantes Cajeros',
    component: () => import('../views/CajerosLanding'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },
  {
    path: '/KioskosLanding',
    name: 'Kioskos Cajeros',
    component: () => import('../views/KioscosLanding'),
    meta: {
      log: false,
      admin: false,

      auth: false,
    }
  },

]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach(async (to, from, next) => {

  let meta_admin = to.matched.some(record => record.meta.admin);

  let meta_log = to.matched.some(record => record.meta.log);
  let meta_auth = to.matched.some(record => record.meta.auth);

  let tokenexp = await userService.tokenexp()
  let check = false //comprobar si esta logeado
  let admin = false;

  let type = ''


  if (tokenexp) { // ver que el token no este expirado.
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath') //limpiar el localstorage
    next()
  }


  let response = await userService.getData()

  if (response.status == 'error') {
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')
  }


  if (response.status == 'success') { //comprobar que sea un login valido.
    check = true;
    let isAdmin = userService.isAdmin(response.result);
    admin = isAdmin.admin;
    type = isAdmin.type;
  }

  let ath = await userService.getAth();


  // borrar el localstorage cada que vayamos a login.

  if (to.path === '/login' && check) {
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')

    next()
  }

  //redireccionamientos por rol.

  if (!meta_log) next();

  if (meta_log && !check) next('/login');

  if (meta_admin) {
    if (admin) {

      if (meta_auth) {
        if (ath) {
          next();
        } else {
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      } else { //...proximamente rutas que no requieran autentificacion
        next()
      }

    } else {
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }
  } else {
    next()
  }


});




export default router

